import React from "react";
// import Banner from "../components/common/banner";
import GalleryList from "../components/Gallery/gallery";
import ImageSlider from "../components/Gallery/ImageSlider";
function Gallery() {
  return (
    <>
      {/* <Banner title="Gallery" /> */}
      <GalleryList />
      <ImageSlider/>
    </>
  );
}

export default Gallery;
