import React from "react";
export const PortfolioCard = ({ src, title,shortDescription,technology}) => {
  return (
    <>
    <div className="card">
      <div className="imag">
            <img src={src} className="card-img-top" alt="..." /></div>
        
            <div className="card-body">
              <h5 className="card-title"> <center>{title}</center></h5>
              <p className="text-center" style={{fontSize:'14px', height:'70px'}}>{shortDescription}</p> 
              <div classname='tech-Body'>
              <div className="techlist">
            {technology.map((tech, index) => (
              <div className="techli" key={index}>
                {tech}
              </div>
            ))}</div>
          </div>
            </div>
          </div>
    </>
  );
};
