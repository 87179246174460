import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row } from "react-bootstrap";
// import nextIcon from "../../assets/images/testimonial/next-icon.svg";
// import prevIcon from '../../assets/images/testimonial/previcon.svg'
function Testimonial() {
  const testimonialLists = [
    {
      content:
        "Great work, fast, quality, and fun to talk too. great communication! let Centillion know what to do and they will do it.",
      skills: "JavaScript, CSS, AngularJS, React.js, Vue.js",
      name: "Matthew B",
      location: "USA",
    },
    {
      content:
        "Centillion is very professional, trustworthy Delivered on time. Will hire them again.",
      skills: "Website Design, Node.js, React.js, Framework",
      name: "Nithin A",
      location: "India",
    },
    {
      content:
        "Centillion helped me complete my task on time and within budget. I highly recommend them",
      skills: " Programming, AngularJS, LINQ, ASP.NET, MVC",
      name: "Harsha D.",
      location: "USA",
    },
    {
      content:
        "Centillion is fantastic. Great communicator.  We highly respect what Centillion brings to the table and would recommend them to others.",
      skills: "JavaScript, CSS, HTML5,  AngularJS",
      name: "Donald M.",
      location: "USA",
    },
    {content:'A great team that delivered excellent work. I look forward to working with them again.'
      ,skills:'Website Design,AWS, Laravel, React.js, Payment Gateway Integration',
      name:'Kristian A',
      location:'Australia'
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
   
  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay:true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  return (
    <section>
      <div className="testimonial_area py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section_title text_center mb-60 mt-3">
                <div className="section_sub_title  mb-3">
                  <h6>Testimonial</h6>
                </div>
                <div className="section_main_title">
                  <h1>What’s Clients Say</h1>
                  <span style={{ fontSize: "20px", fontWeight: "lighter" }}>
                    Credibly grow premier ideas rather than bricks-and-clicks
                    strategic theme areas <br></br> distributed for stand-alone web-readiness.
                  </span>
                </div>
              </div>
            </Col>
           

            <div className="testimonial_list owl-carousel curosel-style">
              <Slider {...settings}>
                {testimonialLists.map((testimonial, index) => {
                  return (
                    <div className="single_testimonial" key={index}>
                        <div className="single_testimonial_content_text">
                          <p className="inbox_text">
                            {testimonial.content}
                            <br />
                            <strong>
                            {testimonial.skills}
                            </strong>
                            <div className="single_testimonial_content_title mt-4"> 
                           
                              <h4>{testimonial.name}</h4>
                              <span>{testimonial.location}</span></div>
                            
                          </p>
                        </div>
                    </div>
                  );
                })}
              </Slider>
            </div>        
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Testimonial;
