import React from "react";
import CircleCI from "../../../assets/images/tech/devops/CircleCI.svg";
import TravisCI from "../../../assets/images/tech/devops/TravisCI.svg";
import Codeship from "../../../assets/images/tech/devops/Codeship.svg";
import Gradle from "../../../assets/images/tech/devops/gradle.svg";

function DevOps() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <img src={CircleCI} alt="CircleCI" />
          <span>CircleCI</span>
        </li>
        <li>
          <img src={TravisCI} alt="TravisCI" />
          <span>TravisCI</span>
        </li>
        <li>
          <img src={Codeship} alt="Codeship" />
          <span>Codeship</span>
        </li>
        <li>
          <img src={Gradle} alt="Gradle" />
          <span>Gradle</span>
        </li>
      </ul>
    </section>
  );
}

export default DevOps;
