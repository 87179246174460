import React from "react";
import prophero from "../../assets/images/companies_list/prophero_.png";
import aptologics from "../../assets/images/companies_list/aptologics_.png";
import techblocks from "../../assets/images/companies_list/techblocks_.png";
import excult from "../../assets/images/companies_list/exult_.png";
import BTM from "../../assets/images/companies_list/BTM_.png";
import curific from "../../assets/images/companies_list/curific_.png";
import dharvi from "../../assets/images/companies_list/dharvee_.png";
import hexadroit from "../../assets/images/companies_list/hexadroit_.png";
import pathways from "../../assets/images/companies_list/pathways_.png";
import phonix from "../../assets/images/companies_list/phoenix_.png";
import xamarin from "../../assets/images/companies_list/Xamarin.png";
function Companies_list() {
  return (
    <section>
      <div className="companies_list_text">
        <h1>50+ Companies Trusted Us</h1>
        <span style={{    fontSize:'20px'}}>50+ Companies Trusted Us</span>
      </div>
      <div class="slider">
        <div class="slide-track">
          <div class="slide ">
            <img src={prophero} height="100" width="250" alt="" />
          </div>
          <div class="slide ">
            <img src={aptologics} height="100" width="250" alt="" />
          </div>
          <div class="slide ">
            <img src={techblocks} height="100" width="250" alt="" />
          </div>
          <div class="slide ">
            <img src={excult} height="100" width="250" alt="" />
          </div>
          <div class="slide ">
		  
            <img src={BTM} height="100" width="250" alt="" />
			
          </div>
          <div class="slide ">
            <img src={dharvi} height="100" width="250" alt="" />
          </div>
		  <div class="slide ">
            <img src={curific} height="100" width="250" alt="" />
          </div>
          <div class="slide ">
            <img src={hexadroit} height="100" width="250" alt="" />
          </div>
          <div class="slide ">
			<div>
            <img src={pathways} height="100" width="250" alt="" />
			</div>
          </div>
          <div class="slide  ">
			<div>
            <img src={phonix} height="100" width="250" alt="" />
			</div>
          </div>
          <div class="slide ">
            <img src={xamarin} height="100" width="250" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Companies_list;
