/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Noimage from "../assets/images/no_img.png";
import { Col, Container } from "react-bootstrap";
// import Banner from "../components/common/banner";
import { useLocation } from "react-router-dom";
let imageUrl = "https://www.centillionsoftechhrm.com/api/static/gallary/";

const GalleryDetails = () => {
  const [gallery, setGallery] = useState();
  let location = useLocation();
  let [albumId] = useState(new URLSearchParams(location.search).get("album"));
  useEffect(() => {
    const galleryList = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/gallery/get?event=${albumId}`;
      const client = axios.create({
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await client.get(url);
      if (result.data.data) {
        setGallery(result.data.data);
      }
    };
    galleryList();
  }, [albumId]);

  return (
    <>
      {/* <Banner title="Gallery" /> */}
      <Container>
        <section class="pt-4 pb-5">
          <div class="container">
            <div className="m-3 text-center text-capitalize">
              <h1>{albumId}</h1>
            </div>
            <div class="row">
              {gallery && gallery.length > 0 ? (
                gallery?.map((item, i) => {
                  let divider =
                    (i + 1) / 3 === 1 ? (
                      <div class="w-100 d-none d-md-block mt-3"></div>
                    ) : (
                      ""
                    );
                  return (
                    <>
                      <div class="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
                        <div class="services-block-three">
                          <a href="#">
                            <img
                              src={imageUrl + item?.image_url}
                              class="w-100 shadow-1-strong rounded mb-4 image-hover"
                              alt="Boat on Calm Water"
                              style={{
                                width: "100%",
                                height: "170px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        </div>

                        {divider}
                      </div>
                    </>
                  );
                })
              ) : (
                <Col>
                  <div className="col-md-6 image-notfound">
                    <h4> Image not available</h4>

                    <img src={Noimage} alt="" className="img-fluid" />
                  </div>
                </Col>
              )}
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default GalleryDetails;
