import React from "react";
import SoupUI from "../../../assets/images/tech/testing/SoupUI.svg";
import Mochas from "../../../assets/images/tech/testing/Mochas.svg";
import Appium from "../../../assets/images/tech/testing/Appium.svg";
import Hoverfly from "../../../assets/images/tech/testing/Hoverfly.svg";
import JMeter from "../../../assets/images/tech/testing/JMeter.svg";
import Katalon from "../../../assets/images/tech/testing/Katalon.svg";
import Selenium from "../../../assets/images/tech/testing/Selenium.svg";
import Gatling from "../../../assets/images/tech/testing/Gatling.svg";
import Saucelabs from "../../../assets/images/tech/testing/Saucelabs.svg";
function Testing() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <img src={SoupUI} alt="SoupUI" />
          <span>CircleCI</span>
        </li>
        <li>
          <img src={Mochas} alt="Mochas" />
          <span>Mochas</span>
        </li>
        <li>
          <img src={Appium} alt="Appium" />
          <span>Appium</span>
        </li>
        <li>
          <img src={Hoverfly} alt="Hoverfly" />
          <span>Hoverfly</span>
        </li>
        <li>
          <img src={JMeter} alt="JMeter" />
          <span>JMeter</span>
        </li>
        <li>
          <img src={Katalon} alt="Katalon" />
          <span>Katalon</span>
        </li>
        <li>
          <img src={Selenium} alt="Selenium" />
          <span>Selenium</span>
        </li>
        <li>
          <img src={Gatling} alt="Gatling" />
          <span>Gatling</span>
        </li>
        <li>
          <img src={Saucelabs} alt="Saucelabs" />
          <span>Saucelabs</span>
        </li>
      </ul>
    </section>
  );
}

export default Testing;
