import React from 'react'
import web_development from '../../assets/images/serviceWeProvide/coding.png'
import mobile_develop from '../../assets/images/serviceWeProvide/app-development.png'
import cloud_deveops from '../../assets/images/serviceWeProvide/cloud_deveops.png'
import web_app from '../../assets/images/serviceWeProvide/web_application.png'
import saas_develop from '../../assets/images/serviceWeProvide/saas_develop.png'
import digital from '../../assets/images/serviceWeProvide/digital_marketing.png'
import { Row ,Col, Container} from 'react-bootstrap'
function servicelist() {
  return (
    <div  className="feature_area pb-70 pt-60">
          
    <h1 className="service_text">Services We Provide</h1> 
    <h3 className="service_inner_text">Credibly grow premier ideas rather than bricks-and-clicks strategic theme areas<br></br>
distributed for stand-alone web-readiness.</h3> <div className="container ">

<Container>
      <Row className='pt-30'>
        <Col lg={4} md={12} sm={12} className=" lines">
        <div className='box'>
          <img src={web_development} className="service_image img-fluid" alt="Web Development" />
          <div className="service_inner_in_text">Custom Software Development</div>
       </div>   <span>
            Centillion offers top custom software development services for the world’s top SME’s startups and enterprises to build world-class solutions. Leverage our low-risk development approach and accelerate to extend your time for marketing to beat the competition.
          </span>
       
        </Col>
        <Col lg={4} md={12} sm={12} className=" lines">
          <div className='box'>
          <img src={mobile_develop} className="service_image img-fluid" alt="Mobile Development" />
          <div className="service_inner_in_text">Mobile App Development</div>
          </div> <span>
            We design and build mobile applications for Android and iOS platforms. Our mobile application developers have the expertise to create highly functional, customized mobile applications using a plethora of pioneer technologies, meeting all your requirements.
          </span>
        </Col>
        <Col lg={4} md={12} sm={12} className=" lines">
        <div className='box'>
          <img src={cloud_deveops} className="service_image img-fluid" alt="Cloud & DevOps" />
          <div className="service_inner_in_text"  style={{ paddingTop: '24px' }}>Cloud & DevOps</div>
          </div>  <span>
            Build secure, scalable and world-class enterprise web applications that grow with your business on the cloud.
          </span>
       
        </Col>
        <Col lg={4} md={12} sm={12} className=" lines">
        <div className='box'>
          <img src={web_app} className="service_image img-fluid" alt="Web Application Development" />
          <div className="service_inner_in_text">Web Application Development</div>
          </div>   <span>
            Centillion offers end-to-end web application development services by building secure, high-performance, intuitive and best-in-class platforms, web applications and products utilizing latest technology and tools to address the requirements of your enterprise or startups.
          </span>
       
        </Col>
        <Col lg={4} md={12} sm={12} className=" lines">
        <div className='box'>
          <img src={saas_develop} className="service_image img-fluid" alt="SaaS Development" />
          <div className="service_inner_in_text">SaaS Development</div>
          </div>   <span>
            As a leading SaaS development company, we offer highly advanced, top-notch successful SaaS-based services and products to our customers worldwide. Centillion follows modern software development practices to meet the customer goal.
          </span>
       
        </Col>
        <Col lg={4} md={12} sm={12} className=" lines">
        <div className='box'>
          <img src={digital} className="service_image img-fluid" alt="Digital Marketing" />
          <div className="service_inner_in_text" style={{ marginTop: '-20px' }}>Digital Marketing</div>
          </div>   <span>
            Content helps drive SEO, creates every bit of the user experience, and is the Centillion that brings your brand personality to life. This kind of livelihood is extremely important when you are meeting customers through a screen.
          </span>
       
        </Col>
      </Row>
    </Container>
    
</div>

</div>
  )
}

export default servicelist
