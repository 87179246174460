import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const LogOut = () => {
  let navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate("/admin");
  }, []);
  return <></>;
};

export default LogOut;
