import React, { useEffect, useState } from "react";
import { Button, Col, Container } from "react-bootstrap";
// import Banner from "../components/common/banner";
import { useLocation, useNavigate } from "react-router-dom";
import Noimage from "../assets/images/no-image.png";

import { postData } from "../api";

let imageUrl = "https://www.centillionsoftechhrm.com/api/static/portfolio/";

const ViewPortFolio = () => {
  const [gallery, setGallery] = useState();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  console.log("isGalleryOpen  --------", isGalleryOpen);
  console.log("gallery  -------", gallery?.otherPhotos);

  const navigation = useNavigate();
  let location = useLocation();

  let [albumId] = useState(new URLSearchParams(location.search).get("id"));
  useEffect(() => {
    const galleryList = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/get`;
      const result = await postData(url, { _id: albumId });
      if (result.data.data) {
        setGallery(result.data.data[0]);
      }
    };
    galleryList();
  }, [albumId]);

  const handleGalleryButtonClick = () => {
    setIsGalleryOpen(true);
  };

  return (
    <>
      {/* <Banner title="PortFolio View" /> */}
      <div class="row" style={{ position: "relative", marginRight: "10px" }}>
        <Col md={4}>
          <button
            class="btn btn-primary"
            style={{
              position: "absolute",
              right: 0,
              margin: "10px",
            }}
            onClick={() => navigation("/potfolio")}
          >
            Back
          </button>
        </Col>
      </div>
      <Container className="mt-3 mb-3">
        <section class="pt-5 pb-5">
          <div class="container">
            <div class="row">
              <Col lg={6}>
                <div class="services-block-three">
                  <img
                    src={
                      gallery?.coverPhoto
                        ? imageUrl + gallery.coverPhoto
                        : Noimage
                    }
                    class="w-100 shadow-1-strong rounded mb-4 image-hover"
                    alt="CoverImage"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="p-3">
                  {gallery && gallery.link ? (
                    <div
                      className="text-end"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        float: "right",
                        marginLeft: "10px",
                      }}
                    >
                      {/* <a
                        href={gallery.link}
                        target="_blank"
                        className="text-center btn btn-primary"
                      >
                        Visit
                      </a> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {gallery?.otherPhotos && gallery?.otherPhotos?.length > 0 ? (
                    <div className="text-end">
                      <Button
                        className="text-center btn btn-primary"
                        onClick={handleGalleryButtonClick}
                      >
                        Gallery
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  <p>
                    <b>Project Name: </b> {gallery && gallery.projectName}
                  </p>
                  <p>
                    <b> Project Short Description: </b>{" "}
                    {gallery && gallery.shortDescription}
                  </p>
                  {/* <p>
                    <b>Cleint Feedback: </b> {gallery && gallery.clientFeedback}
                  </p> */}
                  <div>
                    <b> Project long Description: </b>
                    {gallery &&
                      gallery.longDescription &&
                      gallery.longDescription
                        .split("#")
                        .map((description, index) => (
                          <p key={index}>{description}</p>
                        ))}
                  </div>
                </div>
              </Col>
            </div>
          </div>
          <div class="row">
            {isGalleryOpen &&
              gallery?.otherPhotos?.map((item, i) => {
                let divider =
                  (i + 1) / 3 === 1 ? (
                    <div class="w-100 d-none d-md-block mt-3"></div>
                  ) : (
                    ""
                  );
                let url = `/potfolio/view?id=${item.otherPhotos}`;
                // let url = `/admin-portFolio/view?id=${item._id}`;
                return (
                  <>
                    <div
                      class="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom "
                      key={i}
                    >
                      <div
                        class="services-block-three"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="a">
                          <div
                            className="click"
                            onClick={() => navigation(url)}
                          >
                            <img
                              src={imageUrl + item?.coverPhoto}
                              class="w-100 shadow-1-strong rounded mb-4 image-hover"
                              alt="Boat on Calm Water"
                              style={{
                                // objectFit: "cover",
                                height: "150px",
                              }}
                            />
                            <div
                              className=""
                              style={{ height: "150px", overflow: "hidden" }}
                            >
                              <p className="text-start">
                                projectName : {item.projectName}
                              </p>
                              <p className="text-start">
                                shortDescription : {item.shortDescription}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {divider}
                    </div>
                  </>
                );
              })}
          </div>
        </section>
      </Container>
    </>
  );
};

export default ViewPortFolio;
