import React, { useState } from "react";
// import Banner from "../../components/common/banner";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postData } from "../../api";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

const UserCreate = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  console.log(errors);


  // const reset = () => {
  //   reset(); 
  //   window.scrollTo({ top: 0, behavior: 'smooth' }); 
  // };

  const handleContactSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/register`;

      const result = await postData(url, data);

      if (result.status) {
        setShow(true);
        setTimeout(() => {
          navigation("/admin-user");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      console.log(err);
      setMessage(err.response.data.message);
      setAlert(true);
    }
  };
  return (
    <>
      {/* <Banner title="Admin User Create" /> */}
      <Container>
        <AdminHeader />
        <Row className="m-3 ">
          <Col lg={12} md={12} sm={12} className="">
            <Button
              variant="primary"
              className="mt-4 float-end"
              onClick={() => navigation(-1)}
            >
              Back
            </Button>
          </Col>
        </Row>
        <Row className="m-3 ">
          <Col lg={6} md={6} sm={12} className="offset-lg-3">
            <div className="quote_wrapper">
              <div  className='mb-5' style={{background:'#16c1f7', padding:'5px'}}>
              <h1 className="m-2" style={{color:'#fff'}}> User Create</h1>
              </div>
              <Form onSubmit={handleSubmit(handleContactSubmit)} noValidate>
                <Row>
                  <Col lg={12}>
                    <div className="form_box mb-30" >
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder="Name"
                        style={{border:'1px solid #118ce9'}}
                        {...register("name", { required: true })}
                      />
                      {errors.firstName &&
                        errors.firstName.type === "required" && (
                          <span className="text-danger">
                            firstName is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        autoSave="off"
                        autoComplete="off"
                        placeholder="Email Address"
                        style={{border:'1px solid #118ce9'}}
                        {...register("email", {
                          required: true,
                          pattern:
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span className="text-danger">Email is required</span>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <span className="text-danger">
                          Email Should be "abc@example.com" format
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        type="password"
                        name="password"
                        autoSave="off"
                        autoComplete="off"
                        placeholder="Enter Password"
                        style={{border:'1px solid #118ce9'}}
                        {...register("password", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: "password should be 8 char long",
                          },
                        })}
                      />

                      {errors.password &&
                        errors.password.type === "required" && (
                          <span className="text-danger">
                            Password is required
                          </span>
                        )}
                      {errors.password &&
                        errors.password.type === "minLength" && (
                          <span className="text-danger">
                            {errors.password.message}
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Entered Password"
                        style={{border:'1px solid #118ce9'}}
                        {...register("confirmPassword", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: "confirmPassword should be 8 char long",
                          },
                          validate: (value) => {
                            const { password } = getValues();
                            return (
                              password === value ||
                              "Password and confirm should match!"
                            );
                          },
                        })}
                      />

                      {errors.confirmPassword &&
                        errors.confirmPassword.type === "required" && (
                          <span className="text-danger">
                            confirmPassword is required
                          </span>
                        )}
                      {errors.confirmPassword &&
                        errors.confirmPassword.type === "minLength" && (
                          <span className="text-danger">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      {errors.confirmPassword &&
                        errors.confirmPassword.type === "validate" && (
                          <span className="text-danger">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                    </div>
                    <div className="text_center">
                      <Button type="submit" 
                      class='btn btn-primary btn btn-primary'
                      >
                        Submit
                      </Button>
                      <Button
                        type="button"
                        variant="secondary"
                        style={{ marginLeft: "5px" }}
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>User create successfully</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
      </Container>
    </>
  );
};
export default UserCreate;
