import React from "react";
import aboutvideoimg2 from "../../assets/images/services_page/side-image.svg";
// import banner from "../../assets/images/services_page/about-us-bg.svg";
function Experience() {
  return (
    <section>
     <div className="breatcome_area" >
      {/* <img src={banner} alt="banner"  className="img-fuild"/> */}
      <div className="text_overlay">
    <h1>Services</h1>
    <p>Home || Services</p>
  </div> </div>
      <div className="service_area pt-80 pb-100 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6 pr-50">
              <div className="single_video">
                <div className="video_thumb experience text_center">
                  <img src={aboutvideoimg2} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6">
              <div className="single_about_content">
                <div className="prfs_experience">
                  <div className="heading_experience_title">
                    <h2>Our Experience in
                    <em style={{color:'#118CE9' ,fontWeight:'400'}}> IT Solution &<br>
                    </br> Business</em></h2>
                    
                  </div>
                </div>
                <div className="heading_experience_text pt-40">
                  <p>
                  Centillion Softech is a global consulting company offering solutions, services and platforms based on Open Source, Cloud and Automation technologies. Since 2019, Centillion Softech has been delivering enterprise-class solutions that are built on top-of-the-line platforms and can be easily integrated with existing systems to achieve desired results.{" "}
                  </p>
                  <p>
                  By leveraging multiple delivery models, we help organizations around the world increase revenue, achieve business goals, gain competitive advantage and maximize customer satisfaction while significantly reducing the cost of doing business
                  </p>
                  <p>
                  
With our client-centric consultative approach, we have delivered more than 200 enterprise solutions worldwide to clients in various industries to meet their business and technical needs.
                  </p>
                </div>
                <div className="heading_experience_text pt-3">
                  <p>
                    We offer an increased level of engagement facilitating
                    strategic partnerships with clients by establishing a hub
                    that leverages our processes, infrastructure and operational
                    capabilities.
                  </p>
                  <p>
                    The process infrastructure of Centillion Softech covers the
                    quality management system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;
