import React from "react";
import AspNET from "../../../assets/images/tech/backend/AspNET.svg";
import NETCore from "../../../assets/images/tech/backend/NETCore.svg";
import NodeJS from "../../../assets/images/tech/backend/NodeJS.svg";
import PHP from "../../../assets/images/tech/backend/PHP.svg";
import Python from "../../../assets/images/tech/backend/Python.svg";
import Java from "../../../assets/images/tech/backend/Java.svg";
import RubyOnRails from "../../../assets/images/tech/backend/RubyOnRails.svg";
// import Go from "../../../assets/images/tech/backend/Go.svg";

function Backend() {
  return (
    <section>
      <ul className="tech-list ">
        <li>
          <img src={AspNET} alt="AspNET" />
          <span>AspNET</span>
        </li>
        <li>
          <img src={NETCore} alt="NETCore" />
          <span>NETCore</span>
        </li>

        <li>
          <img src={NodeJS} alt="NodeJS" />
          <span>NodeJS</span>
        </li>

        <li>
          <img src={PHP} alt="PHP" />
          <span>PHP</span>
        </li>

        <li>
          <img src={Python} alt="Python" />
          <span>Python</span>
        </li>

        <li>
          <img src={Java} alt="Java" />
          <span>Java</span>
        </li>

        <li>
          <img src={RubyOnRails} alt="RubyOnRails" />
          <span>RubyOnRails</span>
        </li>

        {/* <li>
          <img src={Go} alt="Go" />
          <span>Go</span>
        </li> */}
      </ul>
    </section>
  );
}

export default Backend;
