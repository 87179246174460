import { Button, Col, Container, Row, Toast } from "react-bootstrap";
// import Banner from "../../components/common/banner";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { postData, deleteData } from "../../api";
import AdminHeader from "../../components/common/admin-header";
import Noimage from "../../assets/images/no-image.png";

let imageUrl = "https://www.centillionsoftechhrm.com/api/static/portfolio/";

const PortFolioView = () => {
  let navigation = useNavigate();
  let [data, setData] = useState(null);
  // let [data, setData] = useState([]);
  console.log("data  --------", data);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/get`;
      const result = await postData(url, {});
      setData(result.data.data);
    };
    get();
  }, []);
  const deleteRecord = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/delete/${id}`;

      const result = await deleteData(url, data);

      if (result.data.success) {
        setMessage("Portfolio delete successfully");
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Portfolio delete failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  return (
    <>
      {/* <Banner title="Admin Portfolio" /> */}
      <Container>
        <AdminHeader />
        {/* <Row className="m-4"> */}
        <Row>
          <Col
            md={12}
            className="text-end mt-4 py-2"
            style={{ paddingRight: "25px" }}
          >
            <Button
              variant="primary"
              className="m-1"
              onClick={() => navigation("/admin-user")}
            >
              Back
            </Button>
            <Button
              onClick={() => navigation("/admin-portFolio/create")}
              className="btn btn-primary"
            >
              New PortFolio
            </Button>
          </Col>
        </Row>
        <section class="pt-1 pb-5">
          <div class="container">
            <div class="row">
              {data && data.length > 0 ? (
                data?.map((item, i) => {
                  let divider =
                    (i + 1) / 3 === 1 ? (
                      <div class="w-100 d-none d-md-block mt-3"></div>
                    ) : (
                      ""
                    );
                  let url = `/admin-portFolio/view?id=${item._id}`;
                  return (
                    <>
                      <div
                        class="col-lg-6 col-md-6 margin-100px-bottom xs-margin-20px-bottom "
                        key={i}
                      >
                        <div
                          class="services-block-three"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="a">
                            <div
                              className="click"
                              onClick={() => navigation(url)}
                            >
                              <img
                                // src={imageUrl + item?.coverPhoto ? }
                                src={
                                  item.coverPhoto
                                    ? imageUrl + item.coverPhoto
                                    : Noimage
                                }
                                class="w-100 shadow-1-strong rounded mb-4 image-hover"
                                alt="Boat on Calm Water"
                                style={{
                                  // objectFit: "cover",
                                  height: "150px",
                                }}
                              />
                              <div
                                className=""
                                style={{ height: "100px", overflow: "hidden" }}
                              >
                                <p className="text-start">
                                  project Name : {item.projectName}
                                </p>
                                <p className="text-start">
                                  Short Description : {item.shortDescription}
                                </p>
                              </div>
                            </div>
                            <div className="button">
                              <Button
                                // variant="primary m-1"
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                                onClick={() =>
                                  navigation(
                                    "/admin-portFolio/edit?id=" + item._id
                                  )
                                }
                              >
                                {/* Edit */}
                                <img
                                  src="edit-3.png"
                                  alt="buttonpng"
                                  border="0"
                                  height="20"
                                  style={{
                                    background: "transparent",
                                    border: "0",
                                    float: "right",
                                  }}
                                />
                              </Button>
                              <Button
                                // variant="danger m-1"
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                                onClick={() => deleteRecord(item._id)}
                              >
                                {/* Delete */}
                                <img
                                  src="delete.png"
                                  alt="buttonpng"
                                  border="0"
                                  height="20"
                                />
                              </Button>
                            </div>
                          </div>
                        </div>

                        {divider}
                      </div>
                    </>
                  );
                })
              ) : (
                <Row className="justify-center">
                  <Col>
                    <div className="col-md-6 image-notfound">
                      <h4> Image not available</h4>

                      <img src={Noimage} alt="" className="img-fluid" />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </section>
      </Container>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
        className="toast"
        bg="success"
      >
        <Toast.Body>{message && message}</Toast.Body>
      </Toast>
      <Toast
        onClose={() => setAlert(false)}
        show={alert}
        delay={3000}
        autohide
        className="toast"
        bg="danger"
      >
        <Toast.Body>{message && message}</Toast.Body>
      </Toast>
    </>
  );
};
export default PortFolioView;
