import React, { useEffect, useState } from "react";
import { postData } from "../../api";
import NoImage from "../../assets/images/no-image.png";
import { PortfolioCard } from "./ap";
import "../actualportfolio/actualportfolio.css";

let imageUrl = "https://www.centillionsoftechhrm.com/api/v1/resources/portfolio/";

function ProtfolioList() {
  const [data, setData] = useState([]);
  const [filterKey, setFilterKey] = useState("*");
  const [activetab, setActivetab] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/get`;
      const result = await postData(url, {});
      setData(result.data.data);
    };
    get();
  }, []);

  useEffect(() => {
    setAnimationClass("fade-exit");
    setTimeout(() => {
      let filtered = data;
      if (filterKey !== "*") {
        filtered = data.filter(item => 
          filterKey === ".webapplicatioin" ? item.Section === "WEB" : item.Section !== "WEB"
        );
      }
      setFilteredData(filtered);
      setAnimationClass("fade-enter");
    }, 500);
  }, [data, filterKey]);

  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);
    setActivetab(key === "*" ? 1 : key === ".webapplicatioin" ? 2 : 3);
  };

  return (
    <>
      <div className="breatcome_area">
        <div className="text_overlay">
          <h1>Portfolio</h1>
          <p>Home || Portfolio</p>
        </div>
      </div>
      <div className="portfolio_area py-5" id="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title text_center mb-50 mt-3">
                <div className="section_sub_title uppercase mb-3">
                  <h6>PORTFOLIO</h6>
                </div>
                <div className="section_main_title">
                  <h1>Our Latest Works For</h1>
                  <h1>Your Business</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4 mt-0">
            <div className="col-lg-12 text-center justify-content-center d-flex">
              <ul className="portfolio-filter">
                <li
                  className={`Btn ${activetab === 1 ? "active" : ""}`}
                  onClick={handleFilterKeyChange("*")}
                >
                  All Works
                </li>
                <li
                  className={`Btn ${activetab === 2 ? "active" : ""}`}
                  onClick={handleFilterKeyChange(".webapplicatioin")}
                >
                  Web Application
                </li>
                <li
                  className={`Btn ${activetab === 3 ? "active" : ""}`}
                  onClick={handleFilterKeyChange(".webdevlopment")}
                >
                  App Development
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            {filteredData.map((x, i) => {
              let type =
                x.Section === "WEB"
                  ? "col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-2" 
                  : "col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-2";
              return (
                <div className={`${type} ${animationClass}`} key={i}>
                  <div className="d-flex justify-content-center">
                    <PortfolioCard
                      technology={x.technology}
                      src={x.coverPhoto ? imageUrl + x.coverPhoto : NoImage}
                      title={x.projectName}
                      shortDescription={x.shortDescription} 
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProtfolioList;
