import React from "react";
import Googlecloud from "../../../assets/images/tech/cloud/Google cloud.svg";
import twilio from "../../../assets/images/tech/cloud/twilio.svg";
import Talend from "../../../assets/images/tech/cloud/Talend.svg";
import Spluck from "../../../assets/images/tech/cloud/Spluck.svg";
import AWS from "../../../assets/images/tech/cloud/AWS.svg";
import Alfresco from "../../../assets/images/tech/cloud/Alfresco.svg";
import Stripe from "../../../assets/images/tech/cloud/Stripe.svg";
import SharePoint from "../../../assets/images/tech/cloud/SharePoint.svg";
import Apigee from "../../../assets/images/tech/cloud/Apigee.svg";
import Pivotal from "../../../assets/images/tech/cloud/Pivotal.svg";
import Salesforce from "../../../assets/images/tech/cloud/Salesforce.svg";
import Docker from "../../../assets/images/tech/cloud/Docker.svg";
import Mulesoft from "../../../assets/images/tech/cloud/Mulesoft.svg";
import Nexmo from "../../../assets/images/tech/cloud/Nexmo.svg";
import kubernetes from "../../../assets/images/tech/cloud/kubernetes.svg";
import Azure from "../../../assets/images/tech/cloud/Azure.svg";
function Cloud() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <img src={Googlecloud} alt="" />
          <span>Googlecloud</span>
        </li>
        <li>
          <img src={twilio} alt="" />
          <span>Twilio</span>
        </li>
        <li>
          <img src={Talend} alt="" />
          <span>Talend</span>
        </li>
        <li>
          <img src={Spluck} alt="" />
          <span>Spluck</span>
        </li>
        <li>
          <img src={AWS} alt="" />
          <span>AWS</span>
        </li>
        <li>
          <img src={Alfresco} alt="" />
          <span>Alfresco</span>
        </li>
        <li>
          <img src={Stripe} alt="" />
          <span>Stripe</span>
        </li>
        <li>
          <img src={SharePoint} alt="" />
          <span>SharePoint</span>
        </li>
        <li>
          <img src={Apigee} alt="" />
          <span>Apigee</span>
        </li>
        <li>
          <img src={Pivotal} alt="" />
          <span>Pivotal</span>
        </li>
        <li>
          <img src={Salesforce} alt="" />
          <span>Salesforce</span>
        </li>
        <li>
          <img src={Docker} alt="" />
          <span>Docker</span>
        </li>
        <li>
          <img src={Mulesoft} alt="" />
          <span>Mulesoft</span>
        </li>
        <li>
          <img src={Nexmo} alt="" />
          <span>Nexmo</span>
        </li>
        <li>
          <img src={kubernetes} alt="" />
          <span>Kubernetes</span>
        </li>
        <li>
          <img src={Azure} alt="" />
          <span>Azure</span>
        </li>
      </ul>
    </section>
  );
}

export default Cloud;
