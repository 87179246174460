import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import { useForm,useFieldArray } from "react-hook-form";
import { postData, putData } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

let imageUrl = "https://www.centillionsoftechhrm.com/api/static/portfolio/";

const PortFolioEdit = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [gallery, setGallery] = useState();
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  
  let location = useLocation();

  let [albumId] = useState(new URLSearchParams(location.search).get("id"));
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "technology",
  });

  const handleFileChange = (e) => {
    const file = Array.from(e.target.file);
    
    setSelectedFiles(file);
  };

  useEffect(() => {
    const portFolioGet = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/get`;
      const result = await postData(url, { _id: albumId });
      console.log("result", result.data.data);
      if (result.data.data) {
        setGallery(result.data.data[0]?.otherPhotos[0]);
        setGallery({ coverPhoto: result.data.data[0]?.coverPhoto });
        setValue("projectName", result.data.data[0]?.projectName);
        setValue("shortDescription", result.data.data[0]?.shortDescription);
        setValue("link", result.data.data[0]?.link);
        setValue("longDescription", result.data.data[0]?.longDescription);
        setValue("Section", result.data.data[0]?.Section);
        setValue("technology", result.data.data[0]?.technology);
        // setValue("otherPhotos", result.data.data[0]?.selectedFiles[0])

        if (result.data.data[0]?.selectedFiles) {
          const selectedFiles = result.data.data[0]?.selectedFiles.map(
            (file) => {
              
              return new File([file], file.name);
            }
          );
          setSelectedFiles(selectedFiles);
          setValue("otherPhotos", selectedFiles);
        }
      }
    };
    portFolioGet();
  }, [albumId, setValue]);

  const handleContactSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/update?field=portfolio`;

      let formData = new FormData();
      formData.append("projectName", data.projectName);
      formData.append("shortDescription", data.shortDescription);
      formData.append("link", data.link ? data.link : "");
      formData.append("longDescription", data.longDescription);
      formData.append("Section", data.Section);
      formData.append("id", albumId);
      formData.append("technology", data.technology);
      if (data.file[0]) formData.append("file", data.file[0]);

      // for (let i = 0; i < selectedFiles.length; i++) {
      //   formData.append(`file[${i}]`, selectedFiles[i]);
      // }

      const result = await putData(url, formData);

      if (result.data.success) {
        setMessage(result.data.message);
        setShow(true);
        setTimeout(() => {
          // window.location.reload();
          navigation("/admin-portFolio");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage(err.response.message);
      setAlert(true);

      setTimeout(() => {
        // window.location.reload();
        navigation("/admin-portFolio");
      }, 2000);
    }
  };

  return (
    <>
      {/* <Banner title="Admin PortFolio Update" /> */}
      <Container>
        <AdminHeader />
        <Row className="m-3 ">
          <Col lg={6} md={6} sm={12} className="offset-lg-3">
            <div className="quote_wrapper">
              <div
                className="mb-5"
                style={{ background: "#16c1f7", padding: "5px" }}
              >
                <h1 className="m-2" style={{ color: "#fff" }}>
                  {" "}
                  PortFolio Update
                </h1>
              </div>
              <Form onSubmit={handleSubmit(handleContactSubmit)} noValidate>
                <Row>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="projectName"
                        placeholder="projectName"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("projectName", { required: true })}
                      />
                      {errors.projectName &&
                        errors.projectName.type === "required" && (
                          <span className="text-danger">
                            projectName is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="shortDescription"
                        placeholder="shortDescription"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("shortDescription", { required: true })}
                      />
                      {errors.shortDescription &&
                        errors.shortDescription.type === "required" && (
                          <span className="text-danger">
                            shortDescription is required
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="link"
                        placeholder="Website Link"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("link", { required: false })}
                      />
                      {errors.link && errors.link.type === "required" && (
                        <span className="text-danger">link is required</span>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <select
                        {...register("Section")}
                        className="form-select"
                        style={{ border: "1px solid #118ce9" }}
                      >
                        <option value="WEB">web Application</option>
                        <option value="APPLICATION">Application</option>
                        <option value="WEBSITE">website</option>
                      </select>
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className="form_box mb-3">
                      <Form.Control
                        type="file"
                        name="file"
                        accept="image/png, image/jpeg"
                        multiple
                        onChange={handleFileChange}
                        style={{
                          padding: "13px",
                          paddingLeft: "23px",
                          border: "1px solid #118ce9",
                        }}
                        className="form-control"
                        // placeholder="Enter Password"
                        {...register("file", {
                          required: false,
                        })}
                      />
                      {/* <span className="text-danger">
                        file type must be jpeg png
                      </span> */}
                      {errors.file && errors.file.type === "required" && (
                        <span className="text-danger">file is required</span>
                      )}
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div class="services-block-three">
                      <a
                        href={imageUrl + gallery?.coverPhoto}
                        style={{ border: "1px solid #118ce9" }}
                      >
                        <img
                          src={imageUrl + gallery?.coverPhoto}
                          class="w-100 shadow-1-strong rounded mb-4 image-hover"
                          alt="CoverImage"
                          style={{
                            width: "100%",
                            height: "170px",
                            objectFit: "cover",
                            border: "1px solid #118ce9",
                          }}
                          value={setGallery?.coverPhoto}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        as="textarea"
                        type="text"
                        name="description"
                        placeholder="description"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("longDescription", { required: true })}
                      />
                      {errors.longDescription &&
                        errors.longDescription.type === "required" && (
                          <span className="text-danger">
                            Description is required
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="form_box mb-3">
                      <Form
                        onSubmit={handleSubmit(handleContactSubmit)}
                        noValidate
                      >
                        {fields.map((field, index) => (
                          <Row key={field.id} sx={{ alignItems: "center" }}>
                            <Col lg={11}>
                              <div className="form_box mb-3">
                                <Form.Control
                                  type="text"
                                  name={`technology[${index}]`}
                                  placeholder={`Technology `}
                                  {...register(`technology[${index}]`)}
                                  style={{
                                    border: "1px solid #118ce9",
                                    display: "flex",
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={1}>
                              <Button
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                                className="ms-0 p-0 mb-3 mt-2"
                                onClick={() => remove(index)}
                              >
                                <img
                                  src="/delete.png"
                                  alt="buttonpng"
                                  border="0"
                                  height="20"
                                />
                              </Button>
                            </Col>
                          </Row>
                        ))}

                        <div className="text_center">
                          <Button
                            type="button"
                            onClick={() => append("")}
                          >
                            Update Technology
                          </Button>
                          {/* <Button type="submit" className="btn btn-primary">Submit</Button> */}
                        </div>
                      </Form>
                    </div>
                  </Col>

                  
                  <Col lg={12}></Col>
                  <div className="text_center">
                    <Button type="submit" className="btn btn-primary">
                      Update
                    </Button>
                    <Button
                      variant="light"
                      className="m-1"
                      onClick={() => navigation("/admin-portFolio")}
                    >
                      Back
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
      </Container>
    </>
  );
};
export default PortFolioEdit;
