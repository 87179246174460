import React from "react";
import cloud_service from "../../assets/images/homePage/cloud_service.png";
import { Col, Container, Row } from "react-bootstrap";
import check_mark from '../../assets/images/homePage/check_mark.png'
import web_development from '../../assets/images/homePage/web_development.png'
function Service() {
  return (
    <section>
      <div className="service_area bg_color2 "  style={{paddingTop:'70px',paddingBottom:'70px'}}>
      <Container >
  <Row>
    <Col
      lg={{ span: 6, order: 1 }}
      sm={{ span: 12, order: 2 }}
      className="d-flex align-items-center justify-content-center " 
    >
    
        <div className="single_service_inner_thumb">
          <img src={cloud_service} alt="Cloud Service" className="service1" />
      
      </div>
    </Col>
    <Col lg={{ span: 6, order: 2 }} sm={{ span: 12, order: 1 }}>
      <div className="section_title text_left mb-55">
        <div className="section_main_title">
          <h1 style={{color:'#118ce9'}}>Cloud<br></br> <h1> Services</h1></h1>
      
        </div>
        <div className="section_content_text pr-70 pt-4">
          <p>
            Build secure, scalable and world-class enterprise web
            applications that grow with your business on the cloud.
          </p>
        </div>
      </div>
      <Row style={{marginTop:'0px'}}>
        <Col lg={6} sm={6} md={6}>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4 >Cloud Databases</h4>
              </div>
            
          </div>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>File Backups</h4>
              </div>
            
          </div>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>File Storage</h4>
              </div>
            
          </div>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>Email Services</h4>
              </div>
            
          </div>
        </Col>
        <Col lg={6} sm={6} md={6}>
        <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>Web Hosting</h4>
              </div>
            
          </div>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>AWS service</h4>
              </div>
            
          </div>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>Remote Desktop</h4>
              </div>
          </div>
          <div className="single_service">
            <img src={check_mark} style={{width: '25px',
    height: '25px' }}></img>
              <div className="single_service_content">
                <h4>Hybrid cloud</h4>
              </div>
            
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
</Container>

      </div>
     <div className="service_area style2 py-5" style={{   background: '#ffff'}}>
      <Container>
        <Row>
          <Col lg={{ span: 6, order: 1 }} sm={{ span: 12, order: 2 }} >
            <div className="section_title text_left mb-55">
              <div className="section_main_title">
                <h1 style={{ color: '#118ce9' }}>Web</h1>
                <h1>Development</h1>
              </div>
              <div className="section_content_text pt-4">
                <p>
                  Centillion offers end-to-end web application development
                  services by building secure, high-performance, intuitive
                  and best-in-class platforms, web applications and products
                  utilizing latest technology and tools to address the
                  requirements of your enterprise or startups.
                </p>
              </div>
            </div>
            <Row>     <Col md={6} lg={6} >       <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>Responsive Design</h4>
              </div>
            </div>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>Application Development</h4>
              </div>
            </div>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>React Development</h4>
              </div>
            </div>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>Technical support</h4>
              </div>
            </div></Col>
            <Col md={6} lg={6}>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>UX/UI Design</h4>
              </div>
            </div>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>Web Development</h4>
              </div>
            </div>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>Angular Development</h4>
              </div>
            </div>
            <div className="single_service">
              <img src={check_mark} style={{ width: '25px', height: '25px' }} alt="Check Mark" />
              <div className="single_service_content">
                <h4>Remote Desktop</h4>
              </div>
            </div>
            </Col>
            </Row>
          </Col>
          <Col lg={{ span: 6, order: 2 }} sm={{ span: 12, order: 1 }}>
      
              <div className="single_service_inner_thumb">
                <img src={web_development} alt="Web Development" className="Service2" />
       
            </div>
          </Col>
        </Row>
       
      </Container>
    </div>
    </section>
  );
}

export default Service;
