import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container } from "react-bootstrap";
import excellenceIcon from "../../assets/images/aboutUspage/excellence.svg";
import innovationIcon from "../../assets/images/aboutUspage/innovation.svg";
import integrityIcon from "../../assets/images/aboutUspage/integrity.svg";
import liabiltyIcon from "../../assets/images/aboutUspage/liablity.svg";
import transparencyIcon from '../../assets/images/aboutUspage/transparency.svg'
function Slider_about_us() {
  const slide = [
    {
      icon: excellenceIcon,
      title: " EXCELLENCE",
      content:
        "We establish standards and processes to engage and motivate employees to produce and deliver exceptional quality in everything we do.",
    },
    {
      icon: innovationIcon,
      title: "INNOVATION",
      content:
        "We encourage thinking and adopting new ideas to meet customer expectations.",
    },
    {
      icon: integrityIcon,
      title: "INTEGRITY",
      content:
        "We act according to strong ethical and moral principles and uphold them at all times, regardless of who is watching.",
    },
    {
      icon: liabiltyIcon,
      title: "LIABILITY",
      content:
        "We understand the value of our work and enable our team members to develop their skills and confidence.",
    },
    {
      icon: transparencyIcon,
      title: "TRANSPARENCY",
      content:
        "We operate the organization in a way that creates openness among leaders, employees, customers and other stakeholders.",
    },
  ];
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section>
      <Container className="mt-70 mb-70 position-relative slice">
        <div className="slider-about">
          <Slider {...settings}>
            {slide.map((slide, index) => {
              return (
                <div className="single_slide" key={index}>
                  <div className="single_slide_content_text">
                    <div className="single_inbox_text">
                      <img src={slide.icon} alt={slide.title} />
                      <h3>{slide.title}</h3>
                      <p>{slide.content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </section>
  );
}

export default Slider_about_us;
