import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router";
import HomePage from "./home";
import About from "./about";
import ServicePage from "./service";
import PotfolioPage from "./portfolio";
import PortfolioViewPage from "./portFolioView";
import PrivateRoutes from "../components/privateRoutes";
import ContactPage from "./contact";
import Pagenotfound from "./pagenotfound";
import Gallery from "./gallery";
import AdminLogin from "./admin";
import UserManagement from "./admin/UserManagement";
import UserCreate from "./admin/UserCreate";
import UserEdit from "./admin/userEdit";
import ContactUsManagement from "./admin/contectUsManagement";
import CreateEvent from "./admin/createEvent";
import Career from "./career";

import GalleryDetails from "./gallary-details";
import AdminGallery from "./admin/admin-gallary";
import AdminGalleryDetails from "./admin/admin-gallery-details";
import ForgetPassword from "./admin/forgetPassword";
import PortFolio from "./admin/portFolio";
import LogOut from "./logout";
import PortFolioCreate from "./admin/portFolioCreate";
import ViewPortFolio from "./admin/viewPortFolio";
import EditPortFolio from "./admin/adminPortfolioEdit";
import AdminCareer from "./admin/admin-career";
import AdminCareerCreate from "./admin/admin-career-create";
import AdminCareerEdit from "./admin/admin-career-edit";
import ResetPassword from "./admin/resetPassowrd";

function Layout() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="App">
      {/* <PrivateRoutes> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/portfolio" element={<PotfolioPage />} />
        <Route path="/potfolio/view" element={<PortfolioViewPage />} />
        <Route path="/contactus" element={<ContactPage />} />
        <Route path="/career" element={<Career />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/admin" element={<AdminLogin />} />

        <Route
          path="/admin-user"
          element={
            <PrivateRoutes>
              <UserManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-user/edit"
          element={
            <PrivateRoutes>
              <UserEdit />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-user/create"
          element={
            <PrivateRoutes>
              <UserCreate />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-contact"
          element={
            <PrivateRoutes>
              <ContactUsManagement />
            </PrivateRoutes>
          }
        />
        <Route path="/gallery-details" element={<GalleryDetails />} />
        <Route
          path="/admin-gallery"
          element={
            <PrivateRoutes>
              <AdminGallery />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/admin-event-create"
          element={
            <PrivateRoutes>
              <CreateEvent />
            </PrivateRoutes>
          }
        />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/admin-gallery-details"
          element={
            // <PrivateRoutes>
            <AdminGalleryDetails />
            // </PrivateRoutes>
          }
        />
        <Route
          path="/admin-portFolio"
          element={
            <PrivateRoutes>
              <PortFolio />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-portFolio/create"
          element={
            <PrivateRoutes>
              <PortFolioCreate />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-portFolio/view"
          element={
            <PrivateRoutes>
              <ViewPortFolio />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-portfolio/edit"
          element={
            <PrivateRoutes>
              <EditPortFolio />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-career"
          element={
            <PrivateRoutes>
              <AdminCareer />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-career/create"
          element={
            <PrivateRoutes>
              <AdminCareerCreate />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-career/edit"
          element={
            <PrivateRoutes>
              <AdminCareerEdit />
            </PrivateRoutes>
          }
        />
        <Route
          path="/admin-gallery"
          element={
            <PrivateRoutes>
              <AdminGallery />
            </PrivateRoutes>
          }
        />
        <Route
          path="/logout"
          element={
            <PrivateRoutes>
              <LogOut />
            </PrivateRoutes>
          }
        />

        {/*<Route path="/teams" element={<Teams />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career/job/:id" element={<JobDetail />} /> */}

        <Route path="*" element={<Pagenotfound />} />
      </Routes>
      {/* </PrivateRoutes> */}
    </div>
  );
}

export default Layout;
