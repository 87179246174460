import React from "react";
import { Link } from "react-router-dom";
import Banner from "../components/common/banner";
function Pagenotfound() {
  return (
    <>
      <Banner title="404" />
      <div className="error_page_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="single_error">
                <div className="single_error_inner">
                  <h1>
                    4<span>0</span>4
                  </h1>
                  <h2>Sorry Page Was Not Found!</h2>

                  <div className="single_error_button">
                    <Link to="/"> Back To Home</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pagenotfound;
