import React from "react";
import Electron from "../../../assets/images/tech/frontend/Electron.svg";
import Native from "../../../assets/images/tech/frontend/Native.svg";
import TypeScript from "../../../assets/images/tech/frontend/TypeScript.svg";
import Xamarin from "../../../assets/images/tech/frontend/Xamarin.svg";
import Vue from "../../../assets/images/tech/frontend/Vue.svg";
import react from "../../../assets/images/tech/frontend/React.svg";
import ReactNative from "../../../assets/images/tech/frontend/ReactNative.svg";
import WPF from "../../../assets/images/tech/frontend/WPF.svg";
import Adnroid from "../../../assets/images/tech/frontend/Adnroid.svg";
import JavaScript from "../../../assets/images/tech/frontend/JavaScript.svg";
import AngularJS from "../../../assets/images/tech/frontend/AngularJS.svg";
import iOS from "../../../assets/images/tech/frontend/iOS.svg";
import Unity from "../../../assets/images/tech/frontend/Unity.svg";
function Frontend() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <img src={Electron} alt="Electron" />
          <span>Electron</span>
        </li>
        <li>
          <img src={Native} alt="Native" />
          <span>Flutter</span>
        </li>
        <li>
          <img src={TypeScript} alt="TypeScript" />
          <span>TypeScript</span>
        </li>
        <li>
          <img src={Xamarin} alt="Xamarin" />
          <span>Xamarin</span>
        </li>
        <li>
          <img src={Vue} alt="Vue" />
          <span>Vue</span>
        </li>
        <li>
          <img src={react} alt="React" />
          <span>React</span>
        </li>
        <li>
          <img src={ReactNative} alt="ReactNative" />
          <span>ReactNative</span>
        </li>
        <li>
          <img src={WPF} alt="WPF" />
          <span>WPF</span>
        </li>
        <li>
          <img src={Adnroid} alt="Adnroid" />
          <span>Adnroid</span>
        </li>
        <li>
          <img src={JavaScript} alt="JavaScript" />
          <span>JavaScript</span>
        </li>
        <li>
          <img src={AngularJS} alt="AngularJS" />
          <span>AngularJS</span>
        </li>
        <li>
          <img src={iOS} alt="iOS" />
          <span>iOS</span>
        </li>
        <li>
          <img src={Unity} alt="Unity" />
          <span>Unity</span>
        </li>
      </ul>
    </section>
  );
}

export default Frontend;
