import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "isotope-layout/dist/isotope.pkgd.min.js";
import "./assets/css/theme-default.css";
import "./assets/css/widget.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Header from "./components/common/header";
import Footer from "./components/common/footer";
import Layout from "./Pages/layout";
import AOS from "aos/dist/aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

function App() {
  AOS.init();

  const location = useLocation();
  const isAdminPath = location.pathname === "/admin";
  const adminPaths = [
    "/admin",
    "/admin-user",
    "/admin-contact",
    "/admin-gallery",
    "/admin-portFolio",
    "/admin-career",
    "/admin-user/create",
    "/admin-user/edit",
    "/admin-event-create",
    "/admin-gallery-details",
    "/admin-portFolio/create",
    "/admin-portFolio/view",
    "/admin-portFolio/edit",
    "/admin-career/create",
    "/admin-career/edit",
  ];

  return (
    <div className="App">
      {/* <Header /> */}
      {isAdminPath ||
      location.pathname === "/forget-password" ||
      location.pathname === "/reset-password" ? null : (
        <Header />
      )}
      <Layout />
      {/* <Footer /> */}
      {/* {isAdminPath  || location.pathname === "/forget-password" ? null : <Footer />} */}
      {adminPaths.includes(location.pathname) ||
      location.pathname === "/forget-password" ? null : (
        <Footer />
      )}
    </div>
  );
}

export default App;
