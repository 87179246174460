import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/centillion_logo/centillion_logo.png";
import { postData } from "../../api";

function Header() {
  const [data, setData] = useState(null);
  const [career, setCareer] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/get`;
      const result = await postData(url, {});
      setData(result.data.data);
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/career/get`;
      const result = await postData(url, {});
      setCareer(result.data.data);
    };
    get();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass =
    location.pathname === "/" ? "navbar dark-header" : "navbar white-header";

  const handleGetQuoteClick = () => {
    navigate("/contactus");
  };

  const socialIconColor = location.pathname === "/" ? "#fff" : "#007bff";

  return (
    <header className="">
      <Navbar
        expand="lg"
        fixed="top"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
        className={scrolled ? `${navbarClass} scrolled` : navbarClass}
      >
        <Container>
          <Row className="w-100 align-items-center mt-0">
            <Col xs={9} md={3}>
              <Navbar.Brand href="/">
                <img src={logo} alt="Centillion Softech" />
              </Navbar.Brand>
            </Col>
            <Col xs={3} md={9} className="coll">
              <div
                className="d-flex align-items-center justify-content-between w-100 top-header"
                style={{
                  borderBottom: "1px solid rgb(255 255 255 / 20%)",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <ul
                  style={{ listStyle: "none" }}
                  className="d-lg-flex align-items-center"
                >
                  <li className="pr-30 pl-15">
                    <i className="fa-regular fa-phone phone_no"></i>
                    <span
                      href="tel:+918866531035"
                      className="phone_no text-white ps-2"
                    >
                      +91-8866531035
                    </span>
                  </li>
                  <li className="ms-2">
                    <span>
                      <i className="fa-light fa-envelope"></i>
                    </span>
                    <span
                      href="mailto:sales@centillionsoftech.com"
                      className="phone_no text-white ps-2"
                    >
                      sales@centillionsoftech.com
                    </span>
                  </li>
                </ul>
                <ul
                  style={{ listStyle: "none" }}
                  className="d-flex align-items-center"
                >
                  <li className="ps-2 text-white ">
                    <a
                      href="https://www.linkedin.com/company/centillionsoftechpvtltd/mycompany/verification/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <i
                        className="fa-brands fa-linkedin-in"
                        style={{
                          margin: "0px 6px 0px 6px",
                          fontSize: "20px",
                          color: socialIconColor,
                        }}
                      ></i>
                    </a>
                  </li>
                  <li className="ps-2 text-white  ">
                    <a
                      href="https://www.facebook.com/p/CentillionSoftech-Pvt-Ltd-100071139090027/?_rdr"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <i
                        className="fa-brands fa-facebook-f"
                        style={{
                          margin: "0px 6px 0px 6px",
                          fontSize: "16px",
                          color: socialIconColor,
                          justifyContent: "center",
                          display: "flex",
                        }}
                      ></i>
                    </a>
                  </li>
                  <li className="ps-2 text-white ">
                    <a
                      href="https://www.instagram.com/centillionsoftech?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <i
                        className="fa-brands fa-instagram"
                        style={{
                          margin: "0px 6px 0px 6px",
                          fontSize: "20px",
                          color: socialIconColor,
                        }}
                      ></i>
                    </a>
                  </li>
                  <li className="ps-2 text-white  ">
                    <a
                      href="https://wa.me/8866531035"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: socialIconColor,
                        textDecoration: "none",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <i
                        className="fa-brands fa-whatsapp"
                        style={{
                          margin: "0px 6px",
                          fontSize: "20px",
                          color: socialIconColor,
                        }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
              <input
                type="checkbox"
                id="checkbox"
                checked={expanded}
                onChange={() => setExpanded(!expanded)}
              />
              <label htmlFor="checkbox" className="toggle">
                <div className="bar bar--top"></div>
                <div className="bar bar--middle"></div>
                <div className="bar bar--bottom"></div>
              </label>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto justify-content-start">
                  <div className="d-lg-flex navlinks">
                    <NavLink
                      to="/"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      <span>Home</span>
                    </NavLink>
                    <NavLink
                      to="/aboutus"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      About Us
                    </NavLink>
                    <NavLink
                      to="/service"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      Services
                    </NavLink>
                    <NavLink
                      to="/portfolio"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      Portfolio
                    </NavLink>
                    <NavLink
                      to="/gallery"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      Gallery
                    </NavLink>
                    <NavLink
                      to="/career"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      Career
                    </NavLink>
                    <NavLink
                      to="/contactus"
                      className="nav-link d-flex"
                      onClick={() => setExpanded(false)}
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </Nav>
                <button
                  className="getQuote d-lg-flex d-none"
                  onClick={() => {
                    handleGetQuoteClick();
                    setExpanded(false);
                  }}
                >
                  Get A Quote
                </button>
              </Navbar.Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
