import React from 'react'
import { Col, Row , Container } from 'react-bootstrap'

function CentillionMap() {
  return (
    <div>
      
    <Row className='mt-0'>
     <Col lg={12}>
                      <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234878.94898760848!2d72.36660967206655!3d23.097696994689993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e832f450cca5f%3A0xdafe8298cb674733!2sCentillion%20Softech!5e0!3m2!1sen!2sin!4v1716893511459!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </Col>
     </Row>

    </div>
  )
}

export default CentillionMap
