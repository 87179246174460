import React from "react";
// import Banner from "../components/common/banner";
import ContactDetail from "../components/Contact/contactDetail";

import ContactForm from "../components/Contact/ContactForm";
import CentillionMap from "../components/Contact/CentillionMap";

function ContactPage() {
  return (
    <>
      {/* <Banner title="Contact Us" /> */}

      <ContactDetail />
      <ContactForm/>
      <CentillionMap/>
    </>
  );
}

export default ContactPage;
