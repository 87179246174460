import React from "react";
import DynamoDB from "../../../assets/images/tech/databases/DynamoDB.svg";
import Cloudera from "../../../assets/images/tech/databases/Cloudera.svg";
import CosmosDB from "../../../assets/images/tech/databases/CosmosDB.svg";
import MySQL from "../../../assets/images/tech/databases/MySQL.svg";
import RethinkDB from "../../../assets/images/tech/databases/RethinkDB.svg";
import Firestore from "../../../assets/images/tech/databases/Firestore.svg";
import SQLServer from "../../../assets/images/tech/databases/SQLServer.svg";
import PostageSQL from "../../../assets/images/tech/databases/PostageSQL.svg";
import Oracle from "../../../assets/images/tech/databases/Oracle.svg";
import MAPR from "../../../assets/images/tech/databases/MAPR.svg";
import Hortonworks from "../../../assets/images/tech/databases/Hortonworks.svg";
import Hadoop from "../../../assets/images/tech/databases/Hadoop.svg";
import Firebase from "../../../assets/images/tech/databases/Firebase.svg";
import Couchbase from "../../../assets/images/tech/databases/Couchbase.svg";
import Neptune from "../../../assets/images/tech/databases/Neptune.svg";
import Redis from "../../../assets/images/tech/databases/Redis.svg";
import MongoDB from "../../../assets/images/tech/databases/MongoDB.svg";
import cassandra from "../../../assets/images/tech/databases/cassandra.svg";
import ElasticSearch from "../../../assets/images/tech/databases/ElasticSearch.svg";
function Databases() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <img src={DynamoDB} alt="DynamoDB" />
          <span>DynamoDB</span>
        </li>
        <li>
          <img src={Cloudera} alt="Cloudera" />
          <span>Cloudera</span>
        </li>
        <li>
          <img src={CosmosDB} alt="CosmosDB" />
          <span>CosmosDB</span>
        </li>
        <li>
          <img src={MySQL} alt="MySQL" />
          <span>MySQL</span>
        </li>
        <li>
          <img src={RethinkDB} alt="RethinkDB" />
          <span>RethinkDB</span>
        </li>
        <li>
          <img src={Firestore} alt="Firestore" />
          <span>Firestore</span>
        </li>
        <li>
          <img src={SQLServer} alt="SQLServer" />
          <span>SQLServer</span>
        </li>
        <li>
          <img src={PostageSQL} alt="PostageSQL" />
          <span>PostageSQL</span>
        </li>
        <li>
          <img src={Oracle} alt="Oracle" />
          <span>Oracle</span>
        </li>
        <li>
          <img src={MAPR} alt="MAPR" />
          <span>MAPR</span>
        </li>
        <li>
          <img src={Hortonworks} alt="Hortonworks" />
          <span>Hortonworks</span>
        </li>
        <li>
          <img src={Hadoop} alt="Hadoop" />
          <span>Hadoop</span>
        </li>
        <li>
          <img src={Firebase} alt="Firebase" />
          <span>Firebase</span>
        </li>
        <li>
          <img src={Couchbase} alt="Couchbase" />
          <span>Couchbase</span>
        </li>
        <li>
          <img src={Neptune} alt="Neptune" />
          <span>Neptune</span>
        </li>
        <li>
          <img src={Redis} alt="Redis" />
          <span>Redis</span>
        </li>
        <li>
          <img src={MongoDB} alt="MongoDB" />
          <span>MongoDB</span>
        </li>
        <li>
          <img src={cassandra} alt="cassandra" />
          <span>Cassandra</span>
        </li>
        <li>
          <img src={ElasticSearch} alt="ElasticSearch" />
          <span>ElasticSearch</span>
        </li>
      </ul>
    </section>
  );
}

export default Databases;
