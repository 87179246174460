import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Backend from "./technologies/backend";
import Cloud from "./technologies/cloud";
import CMS from "./technologies/cms";
import Databases from "./technologies/databases";
import DevOps from "./technologies/devOps";
import Frontend from "./technologies/frontend";
import SourceTools from "./technologies/sourcetools";
import Testing from "./technologies/testing";
function TechnologyList() {
  return (
   
      <section className="technology py-5">
        <div className="container">
          <div className="row mt-0">
            <div className="col-lg-12">
              <div className="section_title text_center mb-55">
                {/* <div className="section_sub_title uppercase mb-3">
                <h6>Technology</h6>
              </div> */}
                <div className="section_main_title">
                  <h1>Tech Stacks</h1>
                </div>
               
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
             
                <Tabs
                  id="controlled-tab-example"
                  defaultActiveKey="frontend"
                  transition={true}
                  className="mb-3"
                >
                  <Tab eventKey="frontend" title="Frontend">
                    <Frontend />
                  </Tab>
                  <Tab eventKey="backend" title="Backend">
                    <Backend />
                  </Tab>
                  <Tab eventKey="databases" title="Databases">
                    <Databases />
                  </Tab>
                  <Tab eventKey="cloud" title="Cloud">
                    <Cloud />
                  </Tab>
                  <Tab eventKey="devOps" title="DevOps">
                    <DevOps />
                  </Tab>
                  <Tab eventKey="cms" title="CMS">
                    <CMS />
                  </Tab>
                  <Tab eventKey="testing" title="Testing">
                    <Testing />
                  </Tab>
                  <Tab eventKey="sourcetools" title="SourceTools">
                    <SourceTools />
                  </Tab>
                </Tabs>
         
            </div>
          </div>
        </div>
      </section>
   
  );
}

export default TechnologyList;
