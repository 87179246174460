import React from "react";
import Successfactors from "../../../assets/images/tech/cms/Successfactors.svg";
import Wordpress from "../../../assets/images/tech/cms/Wordpress.svg";
import Magento from "../../../assets/images/tech/cms/Magento.svg";
import Canvas from "../../../assets/images/tech/cms/Canvas.svg";
import Litmus from "../../../assets/images/tech/cms/Litmus.svg";
import Liferay from "../../../assets/images/tech/cms/Liferay.svg";
import Shopify from "../../../assets/images/tech/cms/Shopify.svg";
import Docebo from "../../../assets/images/tech/cms/Docebo.svg";
import Moodle from "../../../assets/images/tech/cms/Moodle.svg";
import Umbraco from "../../../assets/images/tech/cms/Umbraco.svg";
import Sitecore from "../../../assets/images/tech/cms/Sitecore.svg";
import Totara from "../../../assets/images/tech/cms/Totara.svg";
import Adapt from "../../../assets/images/tech/cms/Adapt.svg";

function CMS() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <img src={Successfactors} alt="" />
          <span>Successfactors</span>
        </li>
        <li>
          <img src={Wordpress} alt="" />
          <span>Wordpress</span>
        </li>
        <li>
          <img src={Magento} alt="" />
          <span>Magento</span>
        </li>
        <li>
          <img src={Canvas} alt="" />
          <span>Canvas</span>
        </li>
        <li>
          <img src={Litmus} alt="" />
          <span>Litmus</span>
        </li>
        <li>
          <img src={Liferay} alt="" />
          <span>Liferay</span>
        </li>
        <li>
          <img src={Shopify} alt="" />
          <span>Shopify</span>
        </li>
        <li>
          <img src={Docebo} alt="" />
          <span>Docebo</span>
        </li>
        <li>
          <img src={Moodle} alt="" />
          <span>Moodle</span>
        </li>
        <li>
          <img src={Umbraco} alt="" />
          <span>Umbraco</span>
        </li>
        <li>
          <img src={Sitecore} alt="" />
          <span>Sitecore</span>
        </li>
        <li>
          <img src={Totara} alt="" />
          <span>Totara</span>
        </li>
        <li>
          <img src={Adapt} alt="" />
          <span>Adapt</span>
        </li>
      </ul>
    </section>
  );
}

export default CMS;
