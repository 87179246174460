import React from "react";
import GitHub from "../../../assets/images/tech/SourceTool/GitHub.png";
import Bitbucket from "../../../assets/images/tech/SourceTool//Bitbucket.png";
import GitLab from "../../../assets/images/tech/SourceTool/gitlab.png";

function SourceTools() {
  return (
    <section>
      <ul className="tech-list">
        <li>
          <div>
            <img src={GitHub} alt="GitHub" />
            <span>GitHub</span>
          </div>
        </li>
        <li>
          <img src={Bitbucket} alt="Bitbucket" />
          <span>Bitbucket</span>
        </li>
        <li>
          <img src={GitLab} alt="GitLab" />
          <span>GitLab</span>
        </li>
      </ul>
    </section>
  );
}

export default SourceTools;
