import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postData, putData } from "../../api";
import { useNavigate, useLocation } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

const UserEdit = (data) => {
  const navigation = useNavigate();
  let location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm();
  let [userId] = useState(new URLSearchParams(location.search).get("id"));
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/get`;

      const result = await postData(url, { _id: userId });
      if (result.data.data.length === 1) {
        setValue("name", result.data.data[0].name);
        setValue("email", result.data.data[0].email);
      } else {
        setMessage("userNot found");
        setAlert(true);
        navigation("/admin-user");
      }
    };
    get();
  }, [userId, setValue, navigation]);

  const handleContactSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/update`;

      const result = await putData(url, { ...data, id: userId });

      if (result.data.success) {
        setShow(true);
        setTimeout(() => {
          navigation("/admin-user");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage(err.response.message);
      setAlert(true);

      setTimeout(() => {
        navigation("/admin-user");
      }, 2000);
    }
  };
  return (
    <>
      {/* <Banner title="Admin User Edit" /> */}
      <Container>
        <AdminHeader />
        <Row className="m-3 ">
          <Col lg={6} md={6} sm={12} className="offset-lg-3">
            <div className="quote_wrapper">
            <div  className='mb-5'style={{background:'#16c1f7', padding:'5px'}}>
              <h1 className="m-2" style={{color:'#fff'}}> User Edit</h1>
              </div>
              <Form onSubmit={handleSubmit(handleContactSubmit)} noValidate>
                <Row>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder="Name"
                        style={{border:'1px solid #118ce9'}}
                        {...register("name", { required: true })}
                      />
                      {errors.firstName &&
                        errors.firstName.type === "required" && (
                          <span className="text-danger">
                            firstName is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        style={{border:'1px solid #118ce9'}}
                        {...register("email", {
                          required: true,
                          pattern:
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span className="text-danger">Email is required</span>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <span className="text-danger">
                          Email Should be "abc@example.com" format
                        </span>
                      )}
                    </div>
                    <div className="text_center">
                      <Button type="submit" variant="primary">
                        Submit
                      </Button>
                      <Button
                        variant="light"
                        className="m-1"
                        onClick={() => navigation(-1)}
                      >
                        Back
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>User update successfully</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
      </Container>
    </>
  );
};
export default UserEdit;
