import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { postData, deleteData } from "../../api";
import { Modal, Button, Container, Toast } from "react-bootstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

function UserManagement() {
  const [data, setData] = useState(null);
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [modelShow, setModelShow] = useState(false);
  const [id, setId] = useState();

  const handleClose = () => window.location.reload();
  const handleShow = (id) => {
    console.log(id);
    setId(id);
    setModelShow(true);
  };
  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/get`;

      const result = await postData(url, {});

      setData(result.data.data);
    };
    get();
  }, []);

  const deleteUser = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/delete/${id}`;

      const result = await deleteData(url, data);
      console.log(result.data.success);
      setModelShow(false);

      if (result.data.success) {
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("User Deletion failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      style: { fontSize: "15px" },
    },
    {
      dataField: "email",
      text: "Email",
      style: { fontSize: "15px" },
    },
    {
      dataField: "role",
      text: "Role",
      style: { fontSize: "15px" },
    },

    {
      dataField: "action",
      text: "Action",
      headerStyle: { width: "150px" },
      formatter: (cellContent, row) => {
        return (
          <>
            {row.role !== "SUPERADMIN" ? (
              <>
                <Button
                  // variant="primary m-1"
                  style={{
                    background: "transparent",
                    border: "0",
                  }}
                  onClick={() => navigation("/admin-user/edit?id=" + row._id)}
                >
                  {/* Edit */}
                  <img src="edit-3.png" alt="buttonpng" border="0" height="20" 
                  style={{
                    background: "transparent",
                    border: "0",
                    float: "right",
                  }}
                  
                  />
                </Button>

                <Button
                  style={{
                    background: "transparent",
                    border: "0",
                  }}
                  onClick={() => handleShow(row._id)}
                >
                  {/* Delete */}

                  <img
                    src="delete.png"
                    alt="buttonpng"
                    border="0"
                    height="20"
                  />
                </Button>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
   <li key={text} role="presentation" style={{marginTop : '-40%'}} >
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ color: 'red', textDecoration: 'none' }} 
      >
        {text}
      </a>
    </li>
  );
  
  const options = {
    sizePerPageOptionRenderer
  };
 

  return (
    <div className="Admin userManagement" >
      {/* <Banner title="Admin User Management" /> */}
      <Container className="mb-1 ">
        <AdminHeader />
        {/* <div className="user-create-button p-4 float-end"> */}
         <div className="user-create-button py-4 d-flex justify-content-end">
          <Button
            variant="primary"
            className="mt-3"
            onClick={() => navigation("/admin")}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="mt-3"
            onClick={() => navigation("/admin-user/create")}
          >
            New User
          </Button>
        </div> 
        
        {/* <div className="table-container"> */}
          <div className="table-div">
          {data && data ? (
            <>
              <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data && data}
              columns={columns}
              // pagination={paginationFactory({ sizePerPage: 10 })}
              pagination={paginationFactory({
                sizePerPage: 5,
                sizePerPageList: [5, 10, 25, 50],
              })}
            />
            </>
          ) : (
            "No data found"
          )}
        </div>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>User delete successfully</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
        <Modal show={modelShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to delete ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteUser(id)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default UserManagement;
